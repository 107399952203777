import { ref, readonly } from "vue";

import API from "@/api";
import { ElNotification } from "element-plus";

export default function useProductsRepository() {
  const products = ref([]);
  const totalResults = ref(0);
  const loading = ref(false);

  const getProducts = async (page, limit, search) => {
    loading.value = true;
    try {
      let result = await API.Products.Products.get(page, limit, search);
      products.value = result.data.List;
      totalResults.value = result.data.TotalResults;
      loading.value = false;
    } catch (error) {
      console.log(error);
    }
  };

  const saveProducts = async (data) => {
    try {
      let result = await API.Products.Products.save(data);
      products.value = result.data.List;
    } catch (error) {
      console.log(error);
    }
  };

  const updateProduct = async (data) => {
    loading.value = true;
    try {
      await API.Products.Products.update(data);
      ElNotification({
        title: "Success",
        message: "Product or Service updated",
        type: "success",
      });
      loading.value = false;
    } catch (error) {
      console.log(error);
      ElNotification({
        title: "Oops",
        message: "Problem while updating Product or Service",
        type: "warning",
      });
      loading.value = false;
    }
  };

  const saveProductsAndReturnValue = async (data) => {
    loading.value = true;
    try {
      const response = await API.Products.Products.save(data);
      loading.value = false;
      ElNotification({
        title: "Success",
        message: "Product or Service created",
        type: "success",
      });
      return response;
    } catch (error) {
      loading.value = false;
      ElNotification({
        title: "Oops",
        message: "Problem while creating Product or Service",
        type: "warning",
      });
    }
  };

  return {
    loading: readonly(loading),
    products,
    totalResults,
    getProducts,
    saveProducts,
    updateProduct,
    saveProductsAndReturnValue,
  };
}
