<template>
  <div class="border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
    <h3 class="text-lg font-medium leading-6 text-gray-900">Products</h3>
    <div class="flex">
      <div class="mt-3 sm:mt-0 sm:ml-4 sm:mr-4">
        <label for="search_candidate" class="sr-only">Search</label>
        <div class="flex rounded-md shadow-sm">
          <div class="relative flex-grow focus-within:z-10">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <SearchIcon class="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              v-model="search"
              name="search_candidate"
              id="search_candidate"
              class="search block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 sm:hidden 2xl:focus:ring-indigo-500"
              placeholder="Search"
            />
            <input
              type="text"
              name="search_candidate"
              id="search_candidate"
              class="search hidden w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:block sm:text-sm"
              placeholder="Search"
            />
          </div>
          <Menu>
            <div class="relative">
              <MenuButton
                type="button"
                class="focus:outline-none relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
              >
                <SortAscendingIcon class="h-5 w-5 text-gray-400" />
                <span class="ml-2">Sort</span>
                <ChevronDownIcon class="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400" />
              </MenuButton>
              <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-out"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
              >
                <MenuItems
                  class="focus:outline-none absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white text-left shadow-lg ring-1 ring-black ring-opacity-5"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="sort-menu"
                >
                  <div class="py-1" role="none">
                    <MenuItem>
                      <a
                        href="#"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                        >Name</a
                      >
                    </MenuItem>
                    <MenuItem>
                      <a
                        href="#"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                        >Date modified</a
                      >
                    </MenuItem>
                    <MenuItem>
                      <a
                        href="#"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        role="menuitem"
                        >Date created</a
                      >
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </div>
          </Menu>
        </div>
      </div>
      <button
        type="button"
        class="focus:outline-none sm:order-0 order-1 ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-purple-500 sm:mr-1"
      >
        <span
          ><FilterIcon class="h-4 w-4 flex-shrink-0 text-gray-400 sm:mr-1"
        /></span>
        Filter
      </button>
      <button
        type="button"
        class="focus:outline-none sm:order-0 order-1 ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-purple-500 sm:mr-1"
      >
        <span
          ><QrcodeIcon class="h-4 w-4 flex-shrink-0 text-gray-400 sm:mr-1"
        /></span>
        <span>Scan</span>
      </button>
      <button
        type="button"
        @click="openCreatePanel"
        class="order-0 focus:outline-none inline-flex items-center rounded-md border border-transparent bg-purple-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:ring-purple-500 sm:order-1 sm:ml-3"
      >
        Create
      </button>
    </div>
  </div>
  <div class="flex flex-col" v-if="products.length > 0">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div
          class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"
        >
          <pagination :pagination="pagination" @changePage="onChangePage" />
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Price
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Total Count
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Locations
                </th>

                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody
              class="divide-y divide-gray-200 bg-white"
              v-if="products.length > 0"
            >
              <tr v-for="product in products" :key="product.Id">
                <td class="whitespace-nowrap px-6 py-4 text-left">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ product.Name }}
                      </div>
                      <div class="text-sm text-gray-500">
                        {{ product.Description }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-left">
                  <div class="text-sm text-gray-900">${{ product.Price }}</div>
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-left">
                  <div class="text-sm text-gray-900">{{ product.Count }}</div>
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-left">
                  <button
                    v-if="product.Locations.length > 0"
                    type="button"
                    v-tippy="{
                      content: 'Product Locations',
                      animation: 'scale',
                      animateFill: false,
                      theme: 'tomato',
                    }"
                    @click="showLocationsInfo(product.Locations)"
                    class="focus:outline-none inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800 focus:ring-purple-500"
                  >
                    <LocationMarkerIcon
                      class="mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
                    />
                    x {{ product.Locations.length }}
                  </button>
                </td>

                <td
                  class="flex space-x-3 whitespace-nowrap px-6 py-4 text-right text-sm font-medium"
                >
                  <button
                    type="button"
                    v-tippy="{
                      content: 'Add Features',
                      animation: 'scale',
                      animateFill: false,
                      theme: 'tomato',
                    }"
                    @click="handleFeatures(product.Id, product.Name)"
                    class="focus:outline-none inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800 focus:ring-purple-500"
                  >
                    <AdjustmentsIcon
                      class="h-5 w-5 flex-shrink-0 text-gray-400"
                    />
                  </button>
                  <button
                    type="button"
                    v-tippy="{
                      content: 'Upload Photos',
                      animation: 'scale',
                      animateFill: false,
                      theme: 'tomato',
                    }"
                    @click="handleUpload(product.Id)"
                    class="focus:outline-none inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800 focus:ring-purple-500"
                  >
                    <UploadIcon class="h-5 w-5 flex-shrink-0 text-gray-400" />
                  </button>
                  <button
                    type="button"
                    v-tippy="{
                      content: 'Edit',
                      animation: 'scale',
                      animateFill: false,
                      theme: 'tomato',
                    }"
                    @click="handleEdit(product.Id)"
                    class="focus:outline-none inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800 focus:ring-purple-500"
                  >
                    <PencilIcon class="h-5 w-5 flex-shrink-0 text-gray-400" />
                  </button>
                  <button
                    type="button"
                    v-tippy="{
                      content: 'Delete',
                      animation: 'scale',
                      animateFill: false,
                      theme: 'tomato',
                    }"
                    @click="handleDelete(product.Id)"
                    class="focus:outline-none inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-red-400 shadow-sm hover:text-red-800 focus:ring-red-500"
                  >
                    <TrashIcon class="h-5 w-5 flex-shrink-0 text-red-400" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      static
      class="fixed inset-0 z-10 overflow-hidden"
      @close="open = false"
      :open="open"
    >
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-2xl">
              <form
                class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
              >
                <div class="flex-1">
                  <!-- Header -->
                  <div class="bg-gray-50 px-4 py-6 text-left sm:px-6">
                    <div class="flex items-start justify-between space-x-3">
                      <div class="space-y-1">
                        <DialogTitle class="text-lg font-medium text-gray-900">
                          New Product
                        </DialogTitle>
                        <p class="text-sm text-gray-500">
                          Get started by filling in the information below to
                          create your new product.
                        </p>
                      </div>
                      <div class="flex h-7 items-center">
                        <button
                          type="button"
                          class="focus:outline-none rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                          @click="open = false"
                        >
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Divider container -->
                  <div
                    class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0"
                  >
                    <!-- Product name -->
                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="product_name"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Product name
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input
                          type="text"
                          v-model="create.name"
                          name="product_name"
                          id="product_name"
                          class="block w-full rounded-md border-gray-300 placeholder-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          placeholder="please enter name"
                        />
                      </div>
                    </div>

                    <!-- Product description -->
                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="product_description"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Description
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <textarea
                          id="product_description"
                          name="product_description"
                          placeholder="please enter meaningful description"
                          v-model="create.description"
                          rows="3"
                          class="block w-full rounded-md border border-gray-300 placeholder-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <!-- Product price -->
                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="warehouse_price"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Price
                        </label>
                      </div>
                      <!-- <div class="sm:col-span-2">
                        <input
                          type="text"
                          placeholder="please enter correct price"
                          name="warehouse_price"
                          id="warehouse_price"
                          v-model="create.price"
                          class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md placeholder-gray-300"
                        />
                      </div> -->
                      <div class="relative mt-1 rounded-md shadow-sm">
                        <div
                          class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                        >
                          <span class="text-gray-500 sm:text-sm"> $ </span>
                        </div>
                        <input
                          type="text"
                          name="price"
                          id="price"
                          v-model="create.price"
                          class="block w-full rounded-md border-gray-300 pl-7 pr-12 placeholder-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          placeholder="0.00"
                          aria-describedby="price-currency"
                        />
                        <div
                          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                        >
                          <span
                            class="text-gray-500 sm:text-sm"
                            id="price-currency"
                          >
                            USD
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- Product binId -->
                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="product_warehouse"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Bin
                        </label>
                      </div>
                      <div class="flex sm:col-span-2">
                        <el-autocomplete
                          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          v-model="binSearch"
                          placeholder="start typing bin id"
                          :fetch-suggestions="querySearch"
                          :trigger-on-focus="false"
                          @select="handleSelect"
                        >
                          <template #suffix>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-6 w-6 sm:mt-2 sm:mb-2 sm:ml-1 sm:mr-1"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              @click="handleIconClick"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
                              />
                            </svg>
                          </template>
                          <template #default="{ item }">
                            <div>
                              <ArchiveIcon
                                class="inline h-4 w-4 text-gray-300 sm:mr-2"
                              />
                              <span class="sm:mr-1"> {{ item.value }}</span>
                              <span class="text-gray-400">{{
                                item.item.Title
                              }}</span>
                            </div>
                            <div class="flex sm:ml-2">
                              <ReplyIcon
                                class="h-4 w-4 rotate-180 transform text-gray-300"
                              />
                              <OfficeBuildingIcon
                                class="h-4 w-4 text-gray-300 sm:ml-2"
                              />
                              <span class="-mt-2 ml-2 text-purple-400">{{
                                item.item.ParentShelf.ParentWarehouse.Name
                              }}</span>
                            </div>
                          </template>
                        </el-autocomplete>

                        <input
                          type="number"
                          min="0"
                          placeholder="count"
                          name="product_count"
                          id="product_count"
                          v-model="bin.count"
                          class="block w-1/2 rounded-md border-gray-300 placeholder-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:ml-2 sm:text-sm"
                        />

                        <button
                          type="button"
                          class="focus:outline-none focus:ring-sky-500 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:ml-2"
                          @click="addBinAndCount"
                        >
                          <PlusIcon
                            class="-ml-2 mr-1 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span>Add</span>
                        </button>
                      </div>
                    </div>
                    <table
                      class="min-w-full divide-y divide-gray-200 p-2"
                      v-if="create.locations.length > 0"
                    >
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Number
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Count
                          </th>
                          <th scope="col" class="relative px-6 py-3">
                            <span class="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(location, indx) in create.locations"
                          :key="location.binId"
                          :class="indx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                        >
                          <td class="whitespace-nowrap px-6 py-4">
                            <div class="ml-4">
                              <div class="text-sm font-medium text-gray-900">
                                {{ location.name }}
                              </div>
                              <div class="text-sm text-gray-500">
                                {{ location.warehouse }} | {{ location.shelf }}
                              </div>
                            </div>
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{ location.number }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{ location.count }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-right text-sm font-medium"
                          >
                            <button
                              type="button"
                              class="text-indigo-600 hover:text-indigo-900"
                              @click="removeBin(location.binId)"
                            >
                              <span>Delete</span>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- Action buttons -->
                <div
                  class="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6"
                >
                  <div class="flex justify-end space-x-3">
                    <button
                      type="button"
                      class="focus:outline-none rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-indigo-500"
                      @click="open = false"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      @click="saveCreateForm"
                      class="focus:outline-none inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-indigo-500"
                    >
                      Create
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 z-20 flex items-end px-4 py-6 sm:items-start sm:p-6"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="show"
          class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <CheckCircleIcon
                  class="h-6 w-6 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-left text-sm font-medium text-gray-900">
                  Successfully saved!
                </p>
                <p class="mt-1 text-left text-sm text-gray-500">
                  Anyone with a link can now view this file.
                </p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button
                  @click="show = false"
                  class="focus:outline-none inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-indigo-500"
                >
                  <span class="sr-only">Close</span>
                  <XIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>

  <!-- Modal -->
  <TransitionRoot as="template" :show="toggleModal">
    <Dialog
      as="div"
      static
      class="fixed inset-0 z-10 overflow-y-auto"
      @close="toggleModal = false"
      :open="toggleModal"
    >
      <div
        class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
          >
            <div class="sm:flex sm:items-start">
              <div
                class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
              >
                <ExclamationIcon
                  class="h-6 w-6 text-red-600"
                  aria-hidden="true"
                />
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  Delete product
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Are you sure you want to remove this product? All of your
                    data will be permanently removed from our servers forever.
                    This action cannot be undone.
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                @click="deleteProduct"
              >
                Remove
              </button>
              <button
                type="button"
                class="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                @click="toggleModal = false"
                ref="cancelButtonRef"
              >
                Cancel
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Locations -->

  <TransitionRoot as="template" :show="toggleLocationsSlideover">
    <Dialog
      as="div"
      static
      class="fixed inset-0 z-20 overflow-hidden"
      @close="toggleLocationsSlideover = false"
      :open="toggleLocationsSlideover"
    >
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 flex max-w-full pl-10">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
              >
                <div class="bg-white py-6 px-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle
                      class="text-lg font-normal text-gray-500 text-white"
                    >
                      Locations
                    </DialogTitle>
                    <div class="ml-3 flex h-7 items-center">
                      <button
                        class="focus:outline-none rounded-md bg-white text-gray-700 hover:text-white focus:ring-2 focus:ring-white"
                        @click="toggleLocationsSlideover = false"
                      >
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <ul class="flex flex-col">
                    <li
                      v-for="location in selectedLocations"
                      :key="location.Id"
                      class="mt-2 mb-2 divide-y divide-gray-200 rounded-lg bg-white shadow"
                    >
                      <div class="flex w-full justify-between space-x-6 p-6">
                        <div class="flex-1 truncate">
                          <div class="mb-2 flex justify-between space-x-3">
                            <h3
                              class="truncate text-sm font-medium text-gray-900"
                            >
                              <OfficeBuildingIcon
                                class="mr-1 inline h-5 w-5 text-gray-400"
                              />
                              {{ location.Warehouse }}
                            </h3>
                            <h3
                              class="truncate text-sm font-medium text-gray-900"
                            >
                              <CollectionIcon
                                class="mr-1 inline h-5 w-5 text-gray-400"
                              />
                              {{ location.Shelf }}
                            </h3>
                          </div>
                          <div class="flex justify-between space-x-3">
                            <h3
                              class="truncate text-sm font-medium text-gray-900"
                            >
                              <ArchiveIcon
                                class="mr-1 inline h-5 w-5 text-gray-400"
                              />
                              {{ location.Bin }}
                            </h3>
                            <h3
                              class="truncate text-sm font-medium text-gray-900"
                            >
                              <CubeIcon
                                class="mr-1 inline h-5 w-5 text-gray-400"
                              />
                              x {{ location.Count }}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <TransitionRoot as="template" :show="toggleFeatures">
    <Dialog
      as="div"
      static
      class="fixed inset-0 z-10 overflow-hidden"
      @close="toggleFeatures = false"
      :open="toggleFeatures"
    >
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-2xl">
              <form
                class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
              >
                <div class="flex-1">
                  <!-- Header -->
                  <div class="bg-gray-50 px-4 py-6 text-left sm:px-6">
                    <div class="flex items-start justify-between space-x-3">
                      <div class="space-y-1">
                        <DialogTitle class="text-lg font-medium text-gray-900">
                          New Product Feature for
                          {{ selectedProductName }}
                        </DialogTitle>
                        <p class="text-sm text-gray-500">
                          Get started by filling in the information below to
                          create your new product feature.
                        </p>
                      </div>
                      <div class="flex h-7 items-center">
                        <button
                          type="button"
                          class="focus:outline-none rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                          @click="open = false"
                        >
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Divider container -->
                  <div
                    class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0"
                  >
                    <!-- Product feature name -->
                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="product_name"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Product Feature Name
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input
                          type="text"
                          v-model="feature.name"
                          name="feature_name"
                          id="feature_name"
                          class="block w-full rounded-md border-gray-300 placeholder-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          placeholder="e.g. Color"
                        />
                      </div>
                    </div>

                    <!-- Product feature Options -->
                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="product_warehouse"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Options
                        </label>
                      </div>
                      <div class="flex sm:col-span-2">
                        <input
                          type="text"
                          placeholder="e.g. Red"
                          name="feature_options_name"
                          id="feature_options_name"
                          v-model="featureOptionsName"
                          class="block w-full rounded-md border-gray-300 placeholder-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:mr-2 sm:text-sm"
                        />

                        <button
                          type="button"
                          class="focus:outline-none focus:ring-sky-500 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:ml-2"
                          @click="addFeatureOptionName"
                        >
                          <PlusIcon
                            class="-ml-2 mr-1 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span>Add</span>
                        </button>
                      </div>
                    </div>
                    <div>
                      <ul class="p-12 text-center">
                        <li
                          v-for="option in feature.featureOptions"
                          :key="option.name"
                          class="text-right"
                        >
                          <span class="pl-4">{{ option.name }}</span>
                          <span class="ml-4"
                            ><button
                              type="button"
                              class="text-indigo-600 hover:text-indigo-900"
                              @click="removeFeature(option.name)"
                            >
                              <TrashIcon
                                class="h-5 w-5 flex-shrink-0 text-red-400"
                              /></button
                          ></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  class="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6"
                >
                  <div class="flex justify-end space-x-3">
                    <button
                      type="button"
                      class="focus:outline-none rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-indigo-500"
                      @click="toggleFeatures = false"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      @click="saveFeatureForm"
                      class="focus:outline-none inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-indigo-500"
                    >
                      Create
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import {
  QrcodeIcon,
  XIcon,
  TrashIcon,
  PencilIcon,
  SearchIcon,
  ChevronDownIcon,
  CheckCircleIcon,
  SortAscendingIcon,
  OfficeBuildingIcon,
  ReplyIcon,
  PlusIcon,
  FilterIcon,
  CollectionIcon,
  ExclamationIcon,
  LocationMarkerIcon,
  ArchiveIcon,
  CubeIcon,
  UploadIcon,
  AdjustmentsIcon,
} from "@heroicons/vue/outline";

import Pagination from "@/common/components/pagination";

import useProductsRepository from "@/composable/useProductsRepository";

import { directive } from "vue-tippy";
import "tippy.js/animations/scale.css";

import API from "@/api";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    Pagination,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    QrcodeIcon,
    TrashIcon,
    PencilIcon,
    SearchIcon,
    ChevronDownIcon,
    SortAscendingIcon,
    CheckCircleIcon,
    OfficeBuildingIcon,
    ReplyIcon,
    PlusIcon,
    FilterIcon,
    CollectionIcon,
    ExclamationIcon,
    LocationMarkerIcon,
    ArchiveIcon,
    CubeIcon,
    UploadIcon,
    AdjustmentsIcon,
  },
  directives: {
    tippy: directive,
  },
  setup() {
    const open = ref(false);
    const show = ref(false);
    const toggleModal = ref(false);
    const toggleLocationsSlideover = ref(false);
    const toggleFeatures = ref(false);
    const featureOptionsName = ref("");
    const binSearch = ref("");
    const selectedProduct = ref(0);
    const selectedProductName = ref("");
    const selectedLocations = ref([]);
    const { products, getProducts } = useProductsRepository();

    const pagination = reactive({
      page: 1,
      pageSize: 10,
      totalResults: 0,
    });

    const create = reactive({
      name: "",
      description: "",
      price: null,
      locations: [],
    });

    const feature = reactive({
      name: "",
      productId: 0,
      featureOptions: [],
    });

    const bin = reactive({
      id: null,
      count: null,
      name: null,
      number: null,
      warehouse: null,
      shelf: null,
    });

    const onChangePage = (page) => {
      pagination.page = page;
      getProducts(pagination.page, pagination.pageSize);
    };

    const saveCreateForm = () => {
      // saveWarehouse(create);
      open.value = false;

      API.Products.Products.save(create).then(() => {
        open.value = false;
        getProducts(pagination.page, pagination.pageSize);
        show.value = true;
      });
    };

    onMounted(() => {
      getProducts(pagination.page, pagination.pageSize);
    });

    const openCreatePanel = () => {
      open.value = true;
    };

    const querySearch = async (queryString, cb) => {
      let query = await API.Products.Warehouses.searchBin(queryString);
      const bins = [];
      query.data.List.forEach((bin) => {
        bins.push({
          value: bin.BinNumber,
          item: bin,
        });

        // call callback function to return bins
        cb(bins);
      });
    };

    const handleSelect = (item) => {
      bin.id = item.item.Id;
      bin.name = item.item.Title;
      bin.number = item.item.BinNumber;
      bin.warehouse = item.item.ParentShelf.ParentWarehouse.Name;
      bin.shelf = item.item.ParentShelf.Title;
    };

    const handleIconClick = (ev) => {
      console.log(ev);
    };

    const addBinAndCount = () => {
      create.locations.push({
        binId: parseInt(bin.id),
        count: parseInt(bin.count),
        name: bin.name,
        number: bin.number,
        warehouse: bin.warehouse,
        shelf: bin.shelf,
      });

      // reset bin form
      binSearch.value = null;
      bin.id = null;
      bin.count = null;
      bin.name = null;
      bin.number = null;
      bin.warehouse = null;
    };

    const handleDelete = (id) => {
      selectedProduct.value = id;
      toggleModal.value = true;
    };

    const deleteProduct = async () => {
      toggleModal.value = false;

      try {
        await API.Products.Products.remove(selectedProduct.value);
      } catch (error) {
        console.log({ error });
      } finally {
        getProducts(pagination.page, pagination.pageSize);
      }
    };

    const showLocationsInfo = (locations) => {
      toggleLocationsSlideover.value = true;
      selectedLocations.value = locations;
    };

    const removeBin = (id) => {
      create.locations = create.locations.filter((item) => item.binId != id);
    };

    const handleFeatures = (id, name) => {
      feature.productId = id;
      toggleFeatures.value = true;
      selectedProduct.value = id;
      selectedProductName.value = name;
    };

    const saveFeatureForm = () => {
      API.Products.Products.saveFeature(feature).then(() => {
        toggleFeatures.value = false;
        getProducts(pagination.page, pagination.pageSize);
      });
    };

    const addFeatureOptionName = () => {
      feature.featureOptions.push({
        name: featureOptionsName.value,
      });

      featureOptionsName.value = "";
    };

    const removeFeature = () => {};

    return {
      bin,
      open,
      show,
      create,
      products,
      pagination,
      getProducts,
      onChangePage,
      saveCreateForm,
      openCreatePanel,
      binSearch,
      querySearch,
      handleIconClick,
      addBinAndCount,
      handleSelect,
      toggleModal,
      handleDelete,
      deleteProduct,
      showLocationsInfo,
      toggleLocationsSlideover,
      selectedLocations,
      selectedProductName,
      removeBin,
      toggleFeatures,
      handleFeatures,
      feature,
      saveFeatureForm,
      featureOptionsName,
      addFeatureOptionName,
      removeFeature,
    };
  },
};
</script>
